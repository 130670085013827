























































import { Component, Vue } from "vue-property-decorator";
import { Mutation } from "vuex-class";

@Component({
  components: {}
})
export default class Consulting extends Vue {
  @Mutation("setNavigationTitle") setNavigationTitle: any;
  @Mutation("setHeaderImage") setHeaderImage: any;
  @Mutation("setHeaderImageOverLay") setHeaderImageOverLay: any;
  @Mutation("setHeaderColor") setHeaderColor: any;

  created() {
    this.setNavigationTitle("Working with you...");
    this.setHeaderImage(
      "https://alliance-hr.s3-eu-west-1.amazonaws.com/ballanced.webp"
    );
    this.setHeaderImageOverLay("inset 0 0 0 2000px rgba(25, 25, 25, 0.05)");
    this.setHeaderColor("fontLightSeaGreen");
  }
}
