import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const NavigationStore = new Vuex.Store({
  state: {
    navigationTitle: "",
    headerImage: {
      image: "",
      overlayColor: "",
      headerColor: ""
    }
  },
  mutations: {
    setNavigationTitle(state, title: string) {
      state.navigationTitle = title;
    },
    setHeaderImage(state, image: string) {
      state.headerImage.image = image;
    },
    setHeaderImageOverLay(state, overlayColor: string) {
      state.headerImage.overlayColor = overlayColor;
    },
    setHeaderColor(state, headerColor: string) {
      state.headerImage.headerColor = headerColor;
    }
  },
  getters: {
    getNavigationTitle: state => {
      return state.navigationTitle;
    },
    getHeaderImage: state => {
      return state.headerImage.image;
    },
    getHeaderImageOverLay: state => {
      return state.headerImage.overlayColor;
    },
    getHeaderColor: state => {
      return state.headerImage.headerColor;
    }
  }
});

export default NavigationStore;
