import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/views/Home.vue";
import Consulting from "@/views/Consulting.vue";
import Coaching from "@/views/Coaching.vue";
import About from "@/views/About.vue";
import Contact from "@/views/Contact.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/consulting",
    name: "Consulting",
    component: Consulting
  },
  {
    path: "/coaching",
    name: "Coaching",
    component: Coaching
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
