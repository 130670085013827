/**
 * Sentry
 */
import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  Vue,
  dsn:
    "https://861eafd2c0204ced9c5d0c82aae82ddc@o335403.ingest.sentry.io/5683736",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  logErrors: true,
  attachProps: true,
  tracingOptions: {
    trackComponents: true
  }
});
