<template>
  <div id="app" class="position-relative min-vh-100">
    <main-header />
    <navigation />
    <div class="container page-content">
      <router-view />
    </div>
    <main-footer />
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

import MainHeader from "@/components/MainHeader";
import Navigation from "@/components/Navigation";
import MainFooter from "@/components/MainFooter";
@Component({
  components: { MainFooter, Navigation, MainHeader }
})
export default class App extends Vue {}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  .page-content {
    padding-bottom: 2.5rem;
  }

  p {
    font-size: 18px;
  }

  ul {
    font-size: 18px;
  }
}
</style>
