/**
 * Bootstrap plugins
 */
import Vue from "vue";

import "@/assets/scss/_config.scss";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/scss/bootstrap.scss";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(BootstrapVue);

const files = require.context("./", true, /\.vue$/i);
files.keys().map(key =>
  Vue.component(
    key
      .split("/")
      .pop()
      ?.split(".")[0] ?? "",
    files(key).default
  )
);
