

































































import { Component, Vue } from "vue-property-decorator";
import { Mutation } from "vuex-class";

@Component({
  components: {}
})
export default class Home extends Vue {
  @Mutation("setNavigationTitle") setNavigationTitle: any;
  @Mutation("setHeaderImage") setHeaderImage: any;
  @Mutation("setHeaderImageOverLay") setHeaderImageOverLay: any;
  @Mutation("setHeaderColor") setHeaderColor: any;
  @Mutation("setHeaderLogo") setHeaderLogo: any;

  created() {
    this.setNavigationTitle("Welcome");
    this.setHeaderImage(
      "https://alliance-hr.s3-eu-west-1.amazonaws.com/landscape.webp"
    );
    this.setHeaderImageOverLay("inset 0 0 0 2000px rgba(255, 255, 255, 0.3)");
    this.setHeaderColor("fontJet");
    this.setHeaderLogo("../assets/images/logo/logo.webp");
  }
}
