import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";

/**
 * Plugins
 */
import "./plugins/bootstrap-vue";
import "./plugins/sentry";

/**
 * Vuex Stores
 */
import NavigationStore from "@/store/NavigationStore";

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
  store: NavigationStore
}).$mount("#app");
