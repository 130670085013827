


































import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component
export default class MainHeader extends Vue {
  /* eslint-disable-next-line */
  @Getter("getNavigationTitle") getNavigationTitle: any;
  @Getter("getHeaderImage") getHeaderImage: any;
  @Getter("getHeaderImageOverLay") getHeaderImageOverLay: any;
  @Getter("getHeaderColor") getHeaderColor: any;
}
