





























import { Component, Vue } from "vue-property-decorator";

@Component
export default class Navigation extends Vue {
  navigationItems: [] = [];

  created() {
    if (this.$router.options.routes) {
      this.$router.options.routes.forEach((route: any, index: number) => {
        Vue.set(this.navigationItems, index, {
          name: route.name,
          path: route.path
        });
      });
    }
  }
}
